import React from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import Me from "../assets/Images/profile-img-mehdi.png";
import Bullet1 from "../assets/Images/bullet-all-img.png";
import Background from "../assets/Images/background.jpg";

const Box = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 65vw;
  height: 55vh;
  display: flex;

  background: linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      )
      bottom,
    linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      )
      top;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  border-left: 2px solid ${(props) => props.theme.body};
  border-right: 2px solid ${(props) => props.theme.text};

  z-index: 1;
`;
const float = keyframes`
0% { transform: translateY(-10px) }
50% { transform: translateY(5px) translateX(5px) }
100% { transform: translateY(-10px) }

`;

const SubBox = styled.div`
  width: 50%;
  position: relative;
  display: flex;

  .pic {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: auto;
    bottom: 1%;
  }
  .pic2 {
    position: absolute;
    bottom: -20%;
    left: 0%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: auto;
    animation: ${float} 4s ease infinite;
  }
  .pic3 {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 99%;
    height: 98%;
    
  }
`;

const Text = styled.div`
  font-size: calc(1em + 1.5vw);
  color: ${(props) => props.theme.body};
  padding: 2rem;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & > *:last-child {
    color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 300;
  }
`;



const Intro = () => {
  return (
    <Box
      initial={{ height: 0 }}
      animate={{ height: "55vh" }}
      transition={{ type: "spring", duration: 2, delay: 1 }}
    >
      <SubBox>
        <Text>
          <h1>Salut,</h1>
          <h3>Je suis Mehdi H.</h3>
          <h6>Passionné par le Design et le développement, je conçois et code des sites Web et des App stylés et fun.</h6>
        </Text>
      </SubBox>
      <SubBox>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2 }}
        >
          <img className="pic3" src={Background} alt="Background Pic" />
          <img className="pic" src={Me} alt="Profile Pic" />
          <img className="pic2" src={Bullet1} alt="Bullet 1 Pic" />
 

        </motion.div>
      </SubBox>
    </Box>
  );
};

export default Intro;
