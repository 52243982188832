// work data

export const Work = [
    {
        id:1,
        name:"Application NECTAAR",
        description:"Application de réservation d'événements culturels - Projet de fin de batch - Bootcamp La Capsule",
        tags:["React Native","Redux","Expo","Node.js","Express","Mongo DB","Bcrypt"],
        
        demo:"https://www.youtube.com/watch?v=JsDuZd58sc4",
        github:"https://github.com/me-hadjadj/Nectaar-App"
    },
    {
        id:2,
        name:"My Mail",
        description:"Interface web de messagerie de type Gmail.",
        tags:["HTML","CSS","DOM","JavaScript","JQuery","Front End"],
        
        demo:"https://my-mails-2022.herokuapp.com",
        github:"https://github.com/me-hadjadj/My-Mails"
        
    },
    {
        id:3,
        name:"Bike Shop",
        description:"Site e-commerce 'from scratch' avec service de paiement Stripe.",
        tags:["HTML","CSS","Front End","Stripe","JavaScript","Node.js","EJS","Bootstrap","Back End"],
        
        demo:"https://bike-shop-fr.herokuapp.com",
        github:"https://github.com/me-hadjadj/bike-shop"
    },
    {
        id:4,
        name:"Weather App",
        description:"Application WEB permettant d'afficher la météo d'une ville sur une carte intéractive.",
        tags:["CSS","Front End","JavaScript","EJS","Responsive","BDD NoSQL","MongoDB","Back End","architecture MVC","API Open Weather Map","Webservice","Sign in","Sign up"],
        
        demo:"https://weather-app-hm.herokuapp.com",
        github:"https://github.com/me-hadjadj/weatherapp"
       
    },{
        id:5,
        name:"Black Board",
        description:"Dashboard de commandes de trottinettes électriques. Il permet de suivre les commandes effectuées par les clients, les messages clients et aussi de visualiser les tâches en attente.",
        tags:["CSS","Front End","JavaScript","EJS","BDD MongoDB","Schéma de modèles de données","Chart.js"],
        
        demo:"https://blackboard-2022.herokuapp.com",
        github:"https://github.com/me-hadjadj/BlackBoard"
        
    },
    {
        id:6,
        name:"Ticketac",
        description:"Site de réservation de billets de trains.",
        tags:["Boostrap","CSS","Front End","JavaScript","EJS","Responsive","BDD NoSQL","Sign in","Sign up"],
        
        demo:"https://ticketac-2022.herokuapp.com",
        github:"https://github.com/me-hadjadj/TICKETAC"
        
    },
    // {
    //     id:7,
    //     name:"Ticketac",
    //     description:"Site de réservation de billets de trains.",
    //     tags:["Boostrap","CSS","Front End","JavaScript","EJS","Responsive","BDD NoSQL","Sign in","Sign up"],
        
    //     demo:"https://ticketac-2022.herokuapp.com",
        
        
    // },
    
  
] 